import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import {
  getCurrentUser,
  loginUser,
  logoutUser,
  registerUser,
} from "../../api/auth";
import { notification } from "antd";

export const initialState = {
  user: null,
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
};

export const getUser = createAsyncThunk(
  "auth/,me",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getCurrentUser();
      return response.data;
    } catch (err) {
      notification.error({
        message:
          err?.response?.data?.message ||
          "Error when trying to get current user data",
      });
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  },
);

export const signIn = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await loginUser(data);

      const token = response.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      notification.error({
        message:
          err?.response?.data?.message || "Error when trying log into account",
      });
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  },
);

export const signUp = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    try {
      const response = await registerUser(data);
      const token = response.token;
      localStorage.setItem(AUTH_TOKEN, token);
      window.location.href = "/dashboard";
      return token;
    } catch (err) {
      notification.error({
        message:
          err?.response?.data?.message || "Error when trying to register",
      });
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  },
);

export const signOut = createAsyncThunk("auth/logout", async () => {
  try {
    await logoutUser();
    return true;
  } catch (err) {
    notification.error({
      message: err?.response?.data?.message || "Error when trying to logout",
    });
    return rejectWithValue(err.response?.data?.message || "Error");
  }
});

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    try {
      const response = {
        data: {
          token: "wVYrxaeNa9OxdnULvde1Au5m5w63",
        },
      };
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  },
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.message = action.payload;
        state.loading = false;
      })
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
        notification.success({
          message: "User logged in successfully",
        });
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.user = null;
        state.redirect = "/";
        localStorage.removeItem(AUTH_TOKEN);
        notification.success({
          message: "User logged out successfully",
        });
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
        state.message = null;
        state.showMessage = false;

        notification.success({
          message: "User registered successfully",
        });
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
